'use-client';
import classNames from 'clsx';
import * as React from 'react';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import type { IMenuToggleProps } from '../MenuToggle.types';
import semanticClassNames from '../WIP_MenuToggle.semanticClassNames';
import styles from './skinComps/inlinePopupToggleCommon.scss';
import { MenuToggleSkinMap } from './skinMap';

const MenuToggle: React.FC<IMenuToggleProps> = props => {
  const {
    id,
    customClassNames = [],
    skin = 'inlinePopupToggleSkin1',
    isOpen = false,
    className,
    onClick,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    translations,
    wrapToggleWithNav = false,
  } = props;

  const menuToggleRef = React.useRef<HTMLDivElement>(null);

  if (!isOpen) {
    menuToggleRef.current?.focus();
  }

  const ariaLabels = {
    openMenu: translations.buttonAriaLabelOpen || 'Open navigation menu',
    closeMenu: translations.buttonAriaLabelClose || 'Close',
  };

  const MenuToggleContent = MenuToggleSkinMap[skin];

  return wrapToggleWithNav ? (
    <nav
      id={id}
      aria-label={translations.navAriaLabel}
      className={classNames(styles.nav, { [styles.toggleOpen]: isOpen })}
    >
      <div
        {...getDataAttributes(props)}
        className={classNames(
          styles.menuToggle,
          isOpen && styles.toggleOpen,
          className,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        ref={menuToggleRef}
        role="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label={`${isOpen ? ariaLabels.closeMenu : ariaLabels.openMenu}`}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classNames(
            styles.buttonContainer,
            isOpen && styles.toggleOpen,
          )}
        >
          <MenuToggleContent isOpen={isOpen} />
        </div>
      </div>
    </nav>
  ) : (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        styles.menuToggle,
        isOpen && styles.toggleOpen,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      ref={menuToggleRef}
      role="button"
      aria-label={`${isOpen ? ariaLabels.closeMenu : ariaLabels.openMenu}`}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          styles.buttonContainer,
          isOpen && styles.toggleOpen,
        )}
      >
        <MenuToggleContent isOpen={isOpen} />
      </div>
    </div>
  );
};

export default MenuToggle;
